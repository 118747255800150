import logo from '../assets/rheintec.svg';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function TopBar() {
    function openEmailClient() {
        console.log("TEST")
        const subject = encodeURIComponent('PSE Inquiry');
        const email = 'service@rheintec.ch';
        window.location.href = `mailto:${email}?subject=${subject}`;
    }
    return (
        <Row className="w-100 m-0 ps-4 pt-3 pb-3 border-bottom border-light align-items-center">
            <Col xs={6}>
                <img src={logo} className='logo' alt="logo" />
            </Col>
            <Col xs={6} className="text-end">
                <Button className='text-light' variant="primary" onClick={openEmailClient}>Contact Us</Button>{' '}
            </Col>
        </Row>

    );
}

export default TopBar;
