import './App.css';
import PriceGraph from './components/graph';
import TopBar from './components/top-bar';
function App() {
  return (
    <div className='w-100'>
      <TopBar />
      <PriceGraph />
    </div>
  );
}

export default App;
