import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Plot from 'react-plotly.js';
import Button from 'react-bootstrap/Button';

const PriceGraph = () => {
    const [usersQuery, setUsersQuery] = useState(500);
    const [queryTotalPrice, setQueryTotalPrice] = useState("");
    const [queryPricePerUser, setQueryPricePerUser] = useState("");
    const [totalPrice, setTotalPrice] = useState([])
    const [pricePerUser, setPricePerUser] = useState([])
    const [users, setUsers] = useState([]);

    function openEmailClient() {
        const subject = encodeURIComponent('PSE Inquiry');
        const email = 'service@rheintec.ch';
        window.location.href = `mailto:${email}?subject=${subject}`;
    }

    function smoothstep(edge0, edge1, x) {
        const t = Math.max(0, Math.min(1, (x - edge0) / (edge1 - edge0)));
        return t * t * (3 - 2 * t);
    }

    function calculatePrice(x) {
        if (x <= 50) return { pricePerUser: 4, totalPrice: 4 * x };

        const linearFormula = (x) => ((4800 / 9950) * x) + 188.94;
        const finalFormula = (x) => 0.35 * x;  // Ensuring €0.4 per user at 20,000 users

        let totalPrice;

        const transitionStart = 1500;
        const transitionEnd = 20000;

        if (x <= transitionStart) {
            totalPrice = linearFormula(x);
        } else if (x >= transitionEnd) {
            totalPrice = finalFormula(x);
        } else {
            const t = smoothstep(transitionStart, transitionEnd, x);
            const price1 = linearFormula(x);
            const price2 = finalFormula(x);
            totalPrice = price1 * (1 - t) + price2 * t;
        }

        let pricePerUser = totalPrice / x;

        // Smooth transition at the beginning
        if (x < 100) {
            const t = smoothstep(50, 100, x);
            const initialPricePerUser = 4;
            pricePerUser = initialPricePerUser * (1 - t) + pricePerUser * t;
            totalPrice = pricePerUser * x;
        }

        return { pricePerUser, totalPrice };
    }

    const generateData = () => {
        const data = [];
        for (let users = 50; users <= 20000; users += 10) {
            const { pricePerUser, totalPrice } = calculatePrice(users);
            data.push({ users, pricePerUser, totalPrice });
        }
        return data;
    };

    useEffect(() => {
        const query = Math.max(usersQuery, 50);
        const { pricePerUser, totalPrice } = calculatePrice(query);
        console.log(`Query: ${query}, Price Per User: ${pricePerUser.toFixed(2)}, Total Price: ${totalPrice.toFixed(2)}`);
        setQueryTotalPrice(totalPrice.toFixed(2));
        setQueryPricePerUser(pricePerUser.toFixed(2));
    }, [usersQuery]);

    useEffect(() => {
        const data = generateData();
        setUsers(data.map(point => point.users));
        setPricePerUser(data.map(point => point.pricePerUser));
        setTotalPrice(data.map(point => point.totalPrice));
        setUsersQuery(500);

        // Log key points for verification
        [50, 500, 9000, 10000, 11000, 15000, 20000].forEach(userCount => {
            const result = calculatePrice(userCount);
            console.log(`Price at ${userCount} users:`,
                `Per User: ${result.pricePerUser.toFixed(2)}`,
                `Total: ${result.totalPrice.toFixed(2)}`);
        });
    }, []);
    return (
        <div className='w-100'>
            <Container className='mt-3 ms-4 mb-4 text-light'>
                <Row className='mt-4'>
                    <Col>
                        <h3>Features</h3>
                        <ul>
                            <li>Patch-Management</li>
                            <li>Config-Management</li>
                            <li>Alert Management</li>
                            <li>Monitoring</li>
                            <li>Hosting</li>
                            <li>1x test per year</li>
                            <li>Guaranteed SLA</li>
                            <li>Dedicated, encapsulated environment</li>
                            <li>Integration and set-up included</li>
                            <li>Prerequisite: Cloudflare or equivalent external DNS provider</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Our Guarantees</h3>
                        <ul>
                            <li>We guarantee that we have the appropriate infrastructure for each customer on an ongoing basis and do not cluster customers on the same environment. Each customer gets a completely walled-off environment that is available exclusively to them and no one else</li>
                            <li>We have automated the one-time integration and activation to such an extent that we offer the integration free of charge</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Plot
                data={[
                    {
                        x: users,
                        y: totalPrice,
                        mode: 'lines+markers',
                        type: 'scatter',
                        name: 'Total Price',
                        line: { color: '#00A3E0' },
                        yaxis: 'y2'
                    },
                    {
                        x: users,
                        y: pricePerUser,
                        mode: 'lines+markers',
                        type: 'scatter',
                        name: 'Price per User',
                        line: { color: '#FF7300' },
                        yaxis: 'y1'
                    }
                ]}
                layout={{
                    xaxis: {
                        title: 'Number of Users',
                        type: 'linear',
                        range: [0, 20000],
                        tickvals: [50, 2000, 4000, 6000, 8000, 10000, 12000, 14000, 16000, 18000, 20000],
                        ticktext: ['50', '2K', '4K', '6K', '8K', '10K', '12K', '14K', '16K', '18K', '20K']
                    },
                    yaxis: {
                        title: 'Price per User (€)',
                        type: 'linear',
                        side: 'left',
                        range: [0, 4.5]
                    },
                    yaxis2: {
                        title: 'Total Price (€)',
                        type: 'linear',
                        side: 'right',
                        overlaying: 'y',
                        range: [0, Math.max(...totalPrice) * 1.1]
                    },
                    autosize: true,
                    plot_bgcolor: '#212529',
                    paper_bgcolor: '#212529',
                    font: { color: '#FFFFFF' },
                    legend: {
                        x: 0.5,
                        y: 1.05,
                        orientation: 'h',
                        xanchor: 'center'
                    },
                    margin: { t: 50, b: 50, l: 100, r: 100 },
                    showlegend: true,
                }}
                useResizeHandler={true}
                style={{ width: '100%', height: '800px' }}
                config={{ responsive: true }}
            />
            <Container className='mt-3 ms-4 mb-4 text-light'>
                <Row className='mt-4'>
                    <Row>
                        <Col>
                            <h3>Pricing</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='align-self-center'>
                            <label>Number of users:</label>
                        </Col>
                        <Col className='align-self-center'>
                            <input
                                onChange={(e) => setUsersQuery(Number(e.target.value))}
                                value={usersQuery}
                                min={50}
                                max={20000}
                                style={{ width: '20rem' }}
                                className='ms-3'
                                type='range'
                            />
                        </Col>
                        <Col className='align-self-center'>
                            <input
                                className='ms-3'
                                type="number"
                                min={50}
                                max={20000}
                                value={usersQuery}
                                onChange={(e) => setUsersQuery(Number(e.target.value))}
                            />
                        </Col>
                        <Col className='align-self-center'>
                            <span className='ms-4' hidden={usersQuery <= 0}>
                                <label>
                                    Total Price: {queryTotalPrice}€/month
                                </label>
                            </span>
                        </Col>
                        <Col className='align-self-center'>
                            <span className='ms-4' hidden={usersQuery <= 0}>
                                <label>
                                    Price Per User: {queryPricePerUser}€
                                </label>
                            </span>
                        </Col>
                        <Col className='align-self-center'>
                            <span className='ms-4' hidden={usersQuery <= 0}>
                                <label>
                                    <Button className='text-light' variant="primary" onClick={openEmailClient}>Contact Us</Button>{' '}
                                </label>
                            </span>
                        </Col>
                    </Row>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <h5>Note:</h5>
                        The price is per month per user and refers to a comprehensive application landscape. If you only want individual apps available in the event of a disaster, we can negotiate the price, because then the infrastructure costs can be drastically reduced
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PriceGraph;